.intro-img {
    width: 30%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    animation: rotation 100s infinite linear;
}

.move_logo {
    width: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
.text-brand{
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    margin-top: 64px;
    font-weight: 600;
  }
  .contact-brand a{
    text-align: center;
    color: #34A753;
    font-family: 'Work Sans', sans-serif;
    text-decoration: none;
}
  .contact-brand{
    text-align: center;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
div.cl1 {
  display: inline-block;
  width: 14px;
  border-radius: 50%;
  height: 14px;
  position: relative;
  -webkit-animation: mymove 2s infinite; 
  -webkit-animation-timing-function: linear; 
  animation: mymove 2s infinite;
  animation-timing-function: linear;
}
div.cl2 {
  animation: mymove1 3s infinite !important;
}
div.cl3 {
  animation: mymove2 4s infinite !important;
}
div.cl4 {
  animation: mymove3 5s infinite !important;
}

@-webkit-keyframes mymove {
  from {
    left: 0%;
    background: white;
  }
  to {
    left: 100%;
    background: #517BBD;
  }
}

@keyframes mymove {
  from {
    left: 0%;
    background: white;
  }
  to {
    left: 100%;
    background: #FBBC16;
  }
}

@-webkit-keyframes mymove1 {
  from {
    left: 0%;
    background: white;
  }
  to {
    left: 100%;
    background: #34A754;
  }
}

@keyframes mymove1 {
  from {
    left: 0%;
    background: white;
  }
  to {
    left: 100%;
    background: #103F72;
  }
}

@-webkit-keyframes mymove2 {
  from {
    left: 20%;
    background: white;
  }
  to {
    left: 100%;
    background: #E84336;
  }
}

@keyframes mymove2 {
  from {
    left: 20%;
    background: white;
  }
  to {
    left: 100%;
    background: #34A754;
  }
}
@-webkit-keyframes mymove3 {
  from {
    left: 10%;
    background: white;
  }
  to {
    left: 100%;
    background: #FBBC16;
  }
}

@keyframes mymove3 {
  from {
    left: 10%;
    background: white;
  }
  to {
    left: 100%;
    background: #E84336;
  }
}
.loader_box {
  width: 70%;
  overflow: hidden;
  margin:50px auto;
  padding-left: 10px;
  text-align: left;
  bottom: 10px;
  left: 15%;
  position: absolute;
}
.blink {
  animation: blink 2s linear infinite;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
}
@keyframes blink {
  50% {
    opacity: 20%;
  }
}
@media (max-width:700px) {
    .text-brand{
        font-size: 16px;
    }   
    .intro-img {
        width: 50%;
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        animation: rotation 100s infinite linear;
    }
    .loader_box {
        width: 80%;
        overflow: hidden;
        padding-left: 10px;
        bottom: 10px;
        left: 7%;
      }
}