.btn-secondary{
  --bs-btn-hover-color: #ffffff !important; 
  --bs-btn-hover-bg: #34A753 !important; 
  --bs-btn-hover-border-color: #34A753 !important; 
}
/* Global Styling For Brand Colors */
.text-brand-green{
  color: #34A753;
}
.text-white{
  text-decoration: none;
}
.bg-brand-primary,.bg-primary{
  background-color: #1842b6;
}
.text-brand-primary{
  color: #1842b6;
}
.btn-secondary{
  background-color: #34A753 !important;
  color: white !important;
}
.banerViewHeight{
 height:calc(100vh - 120px)
}
.foterForm{
 padding: 1rem;
  background-color: #043F75;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
}
.foter_title{
  font-weight: 900;
  color: white;
  /* margin-top: 4%; */

}
.css-7g6z0a-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
color: #fff !important;
}
.css-7g6z0a-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: #fff !important;
}
.css-7g6z0a-MuiButtonBase-root-MuiTableSortLabel-root:hover{
  color: #fff !important;
}

.footer_first{
  padding: 50px 50px 0px 0px;
  background-image: url("/public/assets/foterimgnew2.png");
  height: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
} 


.css-bzv0vy-MuiPaper-root-MuiDrawer-paper{
  position: unset !important;
}
.css-421vol{
  position: unset !important;
}
.css-x5fgd4-MuiTable-root{
  min-width: auto !important;
}
.css-8nvx0t{
  min-width: auto !important;
}
.css-1jdkjdn{
  overflow-x: hidden !important;
}
.css-49hksj-MuiPaper-root-MuiTableContainer-root{
  overflow-x: hidden !important;
}
.social_wrap{
  display: flex;
  justify-content: center;
  align-content: flex-end;
  flex-wrap: wrap;

}
.foter_subtitle{
  color: white;
  font-weight: 600;

}

.whyUs_card{
  /* height: 303px; */
  padding: 10px;
}
.whyUs_card > div {
  height: 273px;
}
.imgk {
  width: 140px;
  margin-right: 5px;
  /* height: 100px; */
  /* animation: scroll 30s linear; */
  /* animation-iteration-count: infinite; */

}
body {
  align-items: center;
  justify-content: center;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
 }
  100% {
    transform: translateX(calc(-250px * 7));
 }
}
.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}
.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slideC {
  height: 100px;
  width: 250px;
}


.foter_inputs .form-control{
  background-color: transparent;
  border-color: white;
}

.foter_inputs .btn-primary{
  background-color: transparent;
  border-color: white;
  margin-right: auto;

}
.foter_inputs .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF;
  /* opacity: 1; Firefox */
}


.clients_wrap .slick-track{
display: flex;
align-items: center;
}





.about_img_2{
  position: absolute;
  top: 30%;
  left: 24%;
  /* height: 27vh; */
}
.services_icon{
  /* background: #D8E4FF; */
  /* border-radius: 8px; */
  width: 60px;
  /* display: flex; */
  /* padding: 12px 0px 0px 0px; */
  justify-content: center;
  height: 60px;
}
.services_img_text{
  position: absolute;
  border-radius: 15px;
  background-color: white;
  width: 84%;
  left: 0;
  overflow: hidden;
  height: 250px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* width: 100px; */
  bottom: -15%;
  padding: 5px 5px 0px 5px;
}
.services_text_title{
  /* font-family: 'Poppins'; */
/* font-style: normal; */
font-weight: 500;
font-size: 1.5rem;
}
.services_text_desp{
  padding: 0px 2px 0px 2px;
    overflow: hidden;
    /* white-space: nowrap; */

    /* line-height: 24px; */
    text-align: justify;
    text-overflow: ellipsis;
    /* height: 39px; */
    max-lines: 2;
    -webkit-line-clamp: 3 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.tech_text_desp{
  padding: 0px 2px 0px 2px;
    overflow: hidden;
    /* white-space: nowrap; */

    /* line-height: 24px; */
    text-align: justify;
    text-overflow: ellipsis;
    /* height: 39px; */
    max-lines: 6;
    -webkit-line-clamp: 6 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
   font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.desc_bg{
  /* background-color:#ffffff; */
  
}
.services_card{
  margin-bottom: 10%;
}
.blue_circule{
  cursor: pointer;
  width: 80px;
  font-size: large;
  padding: 5px 5px 0px 5px;
  margin: auto;
  height: 70px;
  /* left: 290px; */
  left: 0;
  right: 0;
  position: absolute;
  bottom: -33px;
  border-radius: 100%;
  color: white;
  background: #004EFF;
}
.nav-logo{
  width: 250px;
}
/* Nav Bar */
/* -------------------------------- */
.activeRoute{
  color: #34A753 !important;
}
.navbar-nav{
  align-items: flex-start;
}
.navbar-nav a{
  padding: 10px;
  color: #517BBD;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -1px;
  outline: none;
  margin-right: 12px;
}
.fa-comment-dots{
  color: #34A753;
}
.fa-search,.fa-phone{
  color: #517BBD;
}
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
/* -------------------------------- */
/* carousel */
/* -------------------------------- */

.carousel-item {
  position: relative;
}

.carousel-item::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .6);
}

.carousel-caption {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.carousel-item p {
  max-width: 700px;
  margin: 0 auto 35px auto;
}

.carousel-control-prev {
  width: 90px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #1842b6;
  border-radius: 0 50px 50px 0;
  opacity: 1;
}

.carousel-control-prev:hover {
  background: #34A753;
  transition: .8s;
}

.carousel-control-next {
  width: 90px;
  height: 60px;
  position: absolute;
  top: 50%;
  right: 0;
  background: #1842b6;
  border-radius: 50px 0 0 50px;
  opacity: 1;
}

.carousel-control-next:hover {
  background: #34A753;
  transition: .8s;
}
@media (max-width: 500px){
  .carousel-control-next,.carousel-control-prev{
    width: 50px;
    height: 30px;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }
}

.carousel-caption .carousel-content a button.carousel-content-btn1 {
  background: #34A753;;
  color: var(--bs-dark);
  opacity: 1;
  border: 0;
  border-radius: 20px;
}
.carousel-caption {
  position: absolute;
  top:0px;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-caption .carousel-content a button.carousel-content-btn1:hover {
  background: #1842b6;
  color: #ffffff;
  border: 0;
  opacity: 1;
  transition: 1s;
  border-radius: 20px;
}

.carousel-caption .carousel-content a button.carousel-content-btn2 {
  background: #1842b6;
  color: var(--bs-white);
  opacity: 1;
  border: 0;
  border-radius: 20px;
}

.carousel-caption .carousel-content a button.carousel-content-btn2:hover {
  background: #34A753;
  color: var(--bs-dark);
  border: 0;
  opacity: 1;
  transition: 1s;
  border-radius: 20px;
}

#carouselId .carousel-indicators li {
  width: 30px;
  height: 10px;
  background: #1842b6;
  margin: 10px;
  border-radius: 30px;
  opacity: 1;
}

#carouselId .carousel-indicators li:hover {
  background: #34A753;;
  opacity: 1;
}

@media (max-width: 992px) {
  .carousel-item {
      min-height: 500px;
  }
  .blog-icon-2 .btn{
    padding: 0px;
  }
  .carousel-item img {
      min-height: 500px;
      object-fit: cover;
  }

  .carousel-item h1 {
      font-size: 40px !important;
  }

  .carousel-item p {
      font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .carousel-item {
      min-height: 400px;
  }
  
  .carousel-item img {
      min-height: 400px;
      object-fit: cover;
  }

  .carousel-item h1 {
      font-size: 28px !important;
  }

  .carousel-item p {
      font-size: 14px !important;
  }
}


.page-header .breadcrumb-item+.breadcrumb-item::before {
  color: var(--bs-white);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-size: 18px;
  color: var(--bs-white);
}

/* -------------------------------- */
/* Top Bar */
/* -------------------------------- */

.top-link a {
  margin-right: 10px;
  color: #1842b6;
}
.main-topbar-container{
  background-color: #000103 !important;
}
.topbar .top-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
#note {
  width: 500px;
  overflow: hidden;
}

#note small {
  position: relative;
  display: inline-block;
  animation: mymovek 5s infinite;
  animation-timing-function: all;
}
#note small{
  margin-top: 7px;
}
.topbar .top-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.topbar .top-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar .top-link a {
  margin-right: 10px;
}

#note {
  width: 500px;
  overflow: hidden;
}

#note small {
  position: relative;
  display: inline-block;
  animation: mymovek 10s infinite;
  animation-timing-function: all;
}

@keyframes mymovek {
  from {left: -100%;}
  to {left: 100%;}
}

@keyframes mymovek {
  from {left: -100%;}
  to {left: 100%;}
}
.text-note {
  color: #34A753 !important
}
/* -------------------------------- */
.carousel-control-prev, .carousel-control-next{
  display: grid;
}
.about-para{
  text-align: justify;
}
.activeUrl{
  color: #0d6efd !important;
}
.active{
  color:  black;
}
.carousel-indicators {
  display: none;
}
.baner_wrap .slick-next{
right: 25px;
}
.clients_wrap .slick-next{
right: 25px;
}

.baner_wrap .slick-prev{
    left: 25px;
    z-index: 1;
}
.clients_wrap .slick-prev
{
    left: 25px;
    z-index: 1;
}

.clients_wrap .slick-prev:before {
  color: black !important;
}

.clients_wrap .slick-next:before {
  color: black!important;
}

.portfolio-wrap .slick-prev:before {
  color: black !important;
}

.portfolio-wrap .slick-next:before {
  color: black!important;
}

.rotate{
  width: 100%;
  animation: rotation infinite 3s linear;
}
.carousel {
  /* top: 7vh; */
  /* margin-top: 35px; */

}
@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

.image-container {
    position: relative;
    width: 100%;
  }

  .navBarwrap{
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .background-image {
    width: 100%;
    height: auto;
    height: 100vh;
  }
  
  .content-overlay {
    position: absolute;

    text-align: center;
    color: #fff;
  }

  .intro-img {
    width: 25%;
    margin-top: 15%;
  
  }
.move_logo{
    width: 100%;
    animation: 'rotation 100s infinite linear',
}
/*** Testimonial ***/
.testimonial-carousel {
    position: relative;
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 40px;
    height: 100%;
    top: calc(50% - 50px);
    left: -21px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin: 5px 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    background: #FFFFFF;
    border: 1px solid var(--primary);
    border-radius: 40px;
    font-size: 18px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    color: #FFFFFF;
}

.testimonial-carousel .owl-dots {
    margin-top: 35px;
    margin-left: 3rem;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    border: 1px solid var(--primary);
    border-radius: 15px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 30px;
    background: var(--primary);
}


/* Portfolio Section */
/* -------------------------------- */

#portfolio {
  padding: 25px 0;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

#main {
  /* padding: 25px 0; */
  margin-top: 5px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

#portfolio #portfolio-flters {
  padding: 0;
  margin: 5px 0 35px 0;
  list-style: none;
  text-align: center;
}

#portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 15px 15px 15px 0;
  display: inline-block;
  padding: 6px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #007bff;
  border-radius: 50px;
  text-transform: uppercase;
  background: #ecf5ff;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

#portfolio #portfolio-flters li:hover,
#portfolio #portfolio-flters li.filter-active {
  background: #007bff;
  color: #fff;
}

#portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

#portfolio .portfolio-item {
  position: relative;
  overflow: hidden;
  /* margin-bottom: 30px; */
}

#portfolio .portfolio-item .portfolio-wrap {
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  margin: 0;
}

#portfolio .portfolio-item .portfolio-wrap:hover img {
  opacity: 0.4;
  transition: 0.3s;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  opacity: 0;
  transition: 0.2s linear;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info h4 {
  font-size: 22px;
  line-height: 1px;
  font-weight: 700;
  margin-bottom: 14px;
  padding-bottom: 0;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info h4 a {
  color: #fff;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info h4 a:hover {
  color: #007bff;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info p {
  padding: 0;
  margin: 0;
  color: #e2effe;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-preview,
#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-details {
  display: inline-block;
  line-height: 1;
  text-align: center;
  width: 36px;
  height: 36px;
  background: #007bff;
  border-radius: 50%;
  margin: 10px 4px 0 4px;
  cursor: pointer;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-preview i,
#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-details i {
  padding: 6px;
  font-size: 22px;
  color: #fff;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-preview:hover,
#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-details:hover {
  background: #3395ff;
}

#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-preview:hover i,
#portfolio .portfolio-item .portfolio-wrap .portfolio-info .link-details:hover i {
  color: #fff;
}

#portfolio .portfolio-item .portfolio-wrap:hover {
  background: #003166;
}

#portfolio .portfolio-item .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}





.container-new{
  padding-top: 2%;
  padding-bottom: 2%;

    align-items: center;
    background-color: #ecf5ff;
    font-family: 'Open Sans'
}
#demo {
    background: linear-gradient(112deg, #ffffff 50%, antiquewhite 50%);
    max-width: 100%;
   /* height: 35vh; */
    margin: auto
}


@media(max-width:767px) {

}

.carousel-caption img {
    width: 6rem;
    border-radius: 5rem;
    margin-top: 2rem
}

@media(max-width:767px) {
    .carousel-caption img {
        width: 4rem;
        border-radius: 4rem;
        margin-top: 1rem
    }
}

#image-caption {
    font-style: normal;
    font-size: 1rem;
    margin-top: 0.5rem
}

@media(max-width:767px) {
    #image-caption {
        font-style: normal;
        font-size: 0.6rem;
        margin-top: 0.5rem
    }
}

@media(max-width:767px) {
    i {
        padding: 0.8rem
    }
}

.slide-a-in {
   margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 1;
    /* border: 1px solid red; */
    width: 90%;
}
.slide-a{
  position: relative;
}







.arrow{
      color: black;
    font-size: 1.5rem;
    padding: 6px;
}

.container-new .carousel{
  position: sticky !important;
}


@media  (max-width: 380px) {
#demo{
  height: 60vh !important;
}

}


@media  (max-width: 700px) {
#demo{
  /* height: 45vh; */
}

}
@media  (min-width: 700px) and (max-width: 992px) {
#demo{
  height: 50vh;
}

}
@media  (min-width: 500px) and (max-width: 767px) {

#portfolio .portfolio-item .portfolio-wrap{
  width: 80%;
  margin: auto;
}
.nav-logo{
  width: 150px;
}
}

.section-bg {
  background: #ecf5ff;
}
.my_iocn{
  position: unset !important;
  margin: auto 0;
}

.tech-model{
  width: 40%;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    /* position: relative; */
    bottom: 10px;
}
/* Services Section
--------------------------------*/
_wrap{
  display: flex;
  flex-wrap: nowrap;
justify-content: center;}
.icon img{
  width: 80px;
  margin-left: 15px;
}

#services {
  padding: 25px 0 40px 0;

  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}
#divider {
  padding: 0px 0 4px 0;
  box-shadow: 0 4px 2px -2px  rgba(0, 0, 0, 0.1);

}

#services .box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  height: auto;
}

#services .box:hover {
-webkit-transform: translateY(-5px);
  transform: translateY(-5px);  
}

#services .icon {
  position: absolute;
  left: -10px;
  top: calc(50% - 32px);
}

#services .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;
}

#services .title {
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#services .title a {
  color: #111;
}

#services .box:hover .title a {
  color: #007bff;
}


.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background: white;
  margin: 20px 0;
  padding: 45px;
  width: 100%;
  /* border-radius: 25px 0px 25px 0px; */
  min-height: 276px;
  border: 2px #ccc solid;
  color: black;
  cursor: pointer;
}
.box:hover{
  background: #1C4FC3;
  color: white;
}

.sercice_box {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background: white;
  margin: 50px 0;
  width: 100%;
  /* border-radius: 25px 0px 25px 0px; */
  min-height: 276px;
  /* border: 2px #ccc solid; */
  color: black;
  cursor: pointer;
}



.box_logo{
  /* height: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
   min-height: 70px;
  border-radius: 0px 10px 0px 10px;
  border: 1px solid;
  margin-bottom: 20%;
  min-width: 97px;
  padding: 2px;
}

.box_logo:hover{
  background-color: white;
}
.new-box{
  height: 300px;
  overflow: hidden;
  padding: 5px;
  /* margin-bottom: 4%; */
  margin: 1% auto;
  display: none
  
}

#services .description {
  
      padding: 0;
    overflow: hidden;
    /* white-space: nowrap; */

    /* line-height: 24px; */
    text-align: justify;
    text-overflow: ellipsis;
    /* height: 39px; */
    max-lines: 2;
    -webkit-line-clamp: 3 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
#services .descp-left {
  margin-left: 62px !important;
}


/* Technologies Section
--------------------------------*/

#technologies {
  padding: 25px 0;
  background: url(/public/assets/bg-hero.png) center center repeat;
  /* box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1); */
}

#technologies .technologies-wrap {
  /* border-top: 1px solid #d6eaff; */
  /* border-left: 1px solid #d6eaff; */
  margin-bottom: 30px;
}

#technologies .client-logo {
  padding: 64px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* border-right: 1px solid #d6eaff; */
  /* border-bottom: 1px solid #d6eaff; */
  overflow: hidden;
  background: #fff;
  cursor: pointer;
  height: 270px;
}

.tex_align{
  text-align: justify;
}

#technologies .modal-content .col-lg-12 .logo img{
  width: 50%;
 left: 50%;
    transform: translate(-50%, 0);
    position: relative;
}


/* contact Section
--------------------------------*/

#contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 25px 0;
  overflow: hidden;
}

#contact .section-header {
  /* padding-bottom: 30px; */
}

#contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #007bff;
}

#contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  color: #888;
}

#contact .social-links {
  padding-bottom: 20px;
}

#contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #007bff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #007bff;
}

#contact .social-links a:hover {
  background: #007bff;
  color: #fff;
}

#contact .info {
  color: black;
  font-weight: 700;
}

#contact .info i {
  font-size: 32px;
  color: black;
  float: left;
  line-height: 1;
}

#contact .info p {
  padding: 0 0 10px 30px;
  line-height: 28px;
  font-size: 14px;
}

#contact .form #sendmessage {
  color: #007bff;
  border: 1px solid #007bff;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 -10px;
  font-weight: 700;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

#contact .form button[type="submit"] {
  background: #007bff;
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
  transition: 0.3s;
}

#contact .form button[type="submit"]:hover {
  background: #0067d5;
  cursor: pointer;
}

#contact .form .contactForm{
  margin-top: -18px;
}

#contact .contact{
  /* background: black; */
  /* background-image: url('/img/background2.jpg'); */
  border-radius: 10px;
  height: 530px;
  background-color: #ecf5ff;
}
/*==================
career section
====================*/

#career {
  /* box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1); */
  padding: 25px 0;
  overflow: hidden;
}

#career .section-header {
  padding-bottom: 30px;
}

#career .career-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #007bff;
}

#career .career-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  color: #888;
}

#career .social-links {
  padding-bottom: 20px;
}

#career .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #007bff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #007bff;
}

#career .social-links a:hover {
  background: #007bff;
  color: #fff;
}

#career .info {
  color: black;
}

#career .info i {
  font-size: 32px;
  color: #007bff;
  float: left;
  line-height: 1;
}

#career .info p {
  padding: 0 0 10px 36px;
  line-height: 28px;
  font-size: 14px;
}

#career .form #sendmessage {
  color: #007bff;
  border: 1px solid #007bff;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#career .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#career .form #sendmessage.show,
#career .form #errormessage.show,
#career .form .show {
  display: block;
}

#career .form .validation {
  color: red;
  display: none;
  margin: 0 0 -9px;
  font-weight: 700;
  font-size: 13px;
}

#career .form input,
#career .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

#career .form button[type="submit"] {
  background: #007bff;
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
  transition: 0.3s;
}

#career .form button[type="submit"]:hover {
  background: #0067d5;
  cursor: pointer;
}

#career .form .contactForm{
  margin-top: -12px;
}

#career .col-lg-12 p{
  color: cornflowerblue;
  font-weight: 700;
}

#career .form-group label{
  color: cornflowerblue;
}

#career .col-lg-6 img{
  width: 100%;
}


#career .join-us{
  /* background: black;
  background-image: url('/img/background2.jpg'); */
  border-radius: 10px;

margin-top: 2%;
 padding-bottom: 10px;
  background-color: #ecf5ff;
}

#career .hiring{
  display: inline-flex;
}

.punch-info img{
  height: 50px !important;
  /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
}


.nav_logo{
  width: 260px;
}

/* # Footer */

/* #footer {
  background: #00428a;
  padding: 0 0 30px 0;
  color: #eee ;
  font-size: 14px;
}
.footer {
  background: #00428a;
  padding: 0 0 30px 0;
  color: #eee ;
  font-size: 14px;
}

#footer .footer-top {
  background: #004a99;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#footer .footer-top .footer-info p {
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #ecf5ff;
}

.footer .col-lg-3 .social-icons a {
  font-size: 22px;
  display: inline-block;
  background: #007bff;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .col-lg-3 .social-icons a:hover {
  background: #0067d5;
  color: #fff;
}

#footer .col-lg-3 .social-icons{
  margin-top: -16px;
    padding: 5px;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul li {
  padding: 8px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #ecf5ff;
}

#footer .footer-top .footer-links ul a:hover {
  color: #74b5fc;
}

#footer .footer-top .footer-career {
  margin-bottom: 30px;
}

#footer .footer-top .footer-career p {
  line-height: 26px;
}

#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}

#footer .footer-top .footer-newsletter input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}

#footer .footer-top .footer-newsletter input[type="submit"] {
  background: #007bff;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type="submit"]:hover {
  background: #0062cc;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #f1f7ff;
}

#footer .credits a {
  color: #bfddfe;
}

#footer .credits a:hover {
  color: #f1f7ff;
}

.main-bg{width:60%;height: 20rem; margin-bottom: 8px;}
#footer .col-lg-3 img{
  width: 23%;
}
.footer_image{
  width: 100% !important;
}
.mrg_top{
  margin-top: 2.8rem;
} */



/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/

.height_20{
  height: 25px;
  /* background: #ecf5ff; */
}
.height_201{
  height: 25px;
  /* background: #ecf5ff; */
}

.main_heading{
    color: #fff;
  /* margin-bottom: 40px; */
  font-size: 28px;
}

#intro .intro-img {
  width: 25%;
  float: right;
  margin-top: 10%;
  animation: rotation 100s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


/* #intro .intro-img:hover{
  transform:rotate(360deg);
  transition: 2s;
} */
.main-header-cur .carousel::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .6);
}

#intro .intro-info {
  width: 50%;
  margin-top: 15%;
  float: left;
}

#intro .intro-info h2 {
  color: #fff;
  margin-bottom: 0.6rem;
  font-size: 3rem;
  font-weight: 700;
  margin-top: 1rem;
}

#intro .intro-info h2 span {
  color: #74b5fc;
  text-decoration: underline;
}

#intro .intro-info .btn-get-started,
#intro .intro-info .btn-services {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 0 20px 20px 0;
  text-decoration: none;
  color: #fff;
}

#intro .intro-info .btn-get-started {
  background: #007bff;
  border: 2px solid #007bff;
  color: #fff;
}

#intro .intro-info .btn-get-started:hover {
  background: none;
  border-color: #fff;
  color: #fff;
}

#intro .intro-info .btn-services {
  border: 2px solid #fff;
}

#intro .intro-info .btn-services:hover {
  background: #007bff;
  border-color: #007bff;
  color: #fff;
}

.section-header_about{
  padding-top: 10%;
  padding-left: 10%;
}

/*--------------------------------------------------------------
# Responsive Media Queries
--------------------------------------------------------------*/

@media (min-width: 992px) {
  #testimonials .testimonial-item p {
    width: 80%;
  }
}



@media (max-width: 991px) {
  #header {
    height: 60px;
    padding: 10px 0;
  }

  #header .logo h1 {
    font-size: 28px;
    padding: 8px 0;
  }



  #intro .intro-img {
    display: none;
width: 20%;
    float: none;
    margin: 10px auto 15px auto;
  }

  #intro .intro-info {
    width: 80%;
    float: none;
    margin: auto;
    text-align: center;
  }

  #why-us .card {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}



@media (max-width: 767px) {
  #intro .intro-info {
    width: 100%;
    margin-top: 10%;
  }

  #intro .intro-info h2 {
    font-size: 3rem;
    margin-top: unset;

  }

  .section-header p {
    width: 100%;
  }

  #testimonials .testimonial-item {
    text-align: center;
  }

  #testimonials .testimonial-item .testimonial-img {
    float: none;
    margin: auto;
  }

  #testimonials .testimonial-item h3,
  #testimonials .testimonial-item h4,
  #testimonials .testimonial-item p {
    margin-left: 0;
  }
}




@media (MAX-WIDTH: 767PX) {
  #footer .col-lg-3 img{
    width: 12%;
  }
  
  .icon img{
    width: 80px;
  }

  #contact .contact{
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1300px){
#career .col-lg-6 img{
  width: inherit;
  margin: auto;
}
}

@media only screen and (min-width: 992px) and (max-width: 2000px){
  #contact .contact{
    margin-left: -8px;
  }

  #career .join-us{
    margin-left: -8px;
  }
}

@media all and (max-width:991px){
  #contact .contact{
    /* margin-left: 14px;/ */
    margin-right: 13px;
  }
}

@media all and (max-width:991px){
  #career .join-us{
    margin-left: 14px;
    margin-right: 13px;
    width: -webkit-fill-available;
  }
}





.slider-caption {
	 position: absolute;
	 width: 40%;
	 left: 10%;
	 top: 10%;
	 text-align: center;
}
 .slider-caption h2 {
	 color: #fff;
	 text-transform: uppercase;
	 font-size: 60px;
}
 .slider-caption .top-caption {
	 text-align: center;
}
 .slider-caption .top-caption h2 {
	 margin-bottom: -30px;
	 font-family: 'Lora', serif;
}
 .slider-caption .bottom-caption {
	 text-align: center;
}
 .slider-caption .bottom-caption h2 {
	 margin-top: -30px;
	 font-family: 'Lora', serif;
}
 .slider-caption .middle-caption {
	 background-color: rgba(255, 255, 255, .2);
	 box-shadow: 0px 0px 4px #333;
	 display: inline-block;
	 padding: 30px 50px;
	 text-align: center;
	 position: relative;
	 z-index: 99;
	 width: 80%;
}
 .slider-caption .middle-caption h2 {
	 font-family: 'Tangerine', cursive;
	 font-weight: 400;
	 font-size: 90px;
	 line-height: 90px;
	 color: red;
	 text-transform: capitalize;
}
 .slider-caption .middle-caption:before {
	 content: "";
	 position: absolute;
	 left: 50%;
	 top: -60px;
	 width: 1px;
	 height: 20px;
	 background-color: red;
}
 .slider-caption .middle-caption:after {
	 content: "";
	 position: absolute;
	 left: 50%;
	 bottom: -60px;
	 width: 1px;
	 height: 20px;
	 background-color: red;
}
 .slider-caption2 {
	 width: 100%;
	 left: 0;
	 top: 25%;
}
 .slider-caption2 .middle-caption {
	 width: 32%;
}
 .owl-carousel .owl-nav [class*='owl-'] {
	 -webkit-transition: all 0.3s ease;
	 transition: all 0.3s ease;
}
 .owl-carousel .owl-nav [class*='owl-'].disabled:hover {
	 background-color: #d6d6d6;
}
 .owl-carousel {
	 position: relative;
}
 .owl-carousel .owl-next, .owl-carousel .owl-prev {
	 width: 22px;
	 height: 40px;
	 margin-top: -20px;
	 position: absolute;
	 top: 45%;
	 color: #fff;
}
 .owl-carousel .owl-prev {
	 left: 10px;
}
 .owl-carousel .owl-next {
	 right: 10px;
}

.more-logo{
      width: 40px;
    float: right;
    color:gray;
}




@media (width <= 991px) {

#intro .intro-img{
  width: 25%;
}
#intro .intro-info h2{
font-size: 1.5rem;
}
.main_heading{
  font-size: 1rem;
}

}



#technologies .technologies-wrap{
  margin: 0px 15px ;
}


@media (max-width: 500px) {

#technologies .technologies-wrap{
  margin: 0px 23px !important;
}

}
@media (max-width: 574px) {

.margin_mx{
  margin: unset !important;
  padding: 0px !important;
}

.modal-content_ {
  width: 90%;
}
.about_img_2{
  display: none;
}
.nav-logo{
  width: 140px;
}

}



@media (max-width: 400px) {
  #intro .intro-img {
      width: 29.5%;
    float: none;
    margin: 13px auto 16px auto !important;

}
}


@media (max-width: 500px) {



  #intro .intro-info .btn-get-started, #intro .intro-info .btn-services {

    font-size: 8px;
    font-weight: 600;

    padding: 5px 14px;

}
.carousel {
    /* top: 7vh; */
 
}
#about {
  background: #fff;
  /* margin-top: 7.4vh; */
      box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
}
#demo{
     width: 90% !important;

}

.button_wrap{
  display: flex;
  justify-content: center;
}

.my_class{
  width: 90%;
  margin: auto;
}
tro-info h2 {
    font-size: 1.3rem;
}
.about-img{
  width: 60px;
}


.poltfolio-img{
  display: block;
  width: 70%;
  margin: 0 auto;
}


.client-logo .img-fluid {
  max-width: 120px;
}

#technologies .client-logo{
  height: 150px;
}


#technologies .modal-content .col-lg-12 .logo img{
  width: 40%;

}

.pyton-img{
  width: 30% !important;
}

}
.testimonial-carousel .carousel-inner{
  border-left: 1px solid #1842b6;
  padding-left: 20px;
}
.testimonial-carousel .carousel-control-prev{
  margin: 5px 0;
  width: 40px;
  height: 40px;
  display: flex;
  top: 38%;
  left: -20px;
  align-items: center;
  justify-content: center;
  color: #1842b6;
  background: #FFFFFF;
  border: 1px solid #1842b6;
  border-radius: 40px;
  font-size: 18px;
  transition: .5s;
  text-decoration: none;
}
.testimonial-carousel .carousel-control-next {
  margin: 5px 0;
  width: 40px;
  height: 40px;
  display: flex;
  left: -20px;
  top:58%;
  align-items: center;
  justify-content: center;
  color: #1842b6 !important;
  background: #FFFFFF;
  border: 1px solid #1842b6;
  border-radius: 40px;
  font-size: 18px;
  transition: .5s;
  text-decoration: none;
}
.testimonial-carousel .carousel-control-prev i ,.testimonial-carousel .carousel-control-next i{
  color: #1842b6 !important;
}
.text-left{
text-align: left !important;
}
@media (max-width: 992px){
  .testimonial-carousel .carousel-item img {
  min-height: 60px !important;
}
  .testimonial-carousel .carousel-item {
  min-height: 220px !important;
}
}

.blog-item .blog-btn {
  z-index: 2;
}

.blog-btn .blog-btn-icon {
  height: 50px;
  position: relative;
  overflow: hidden;
}

.blog-btn-icon .blog-icon-2 {
  display: flex;
  position: absolute;
  top: 6px;
  left: -140px;
  
}

.blog-btn-icon:hover .blog-icon-2 {
  transition: 1s;
  left: 5px;
  top: 6px;
  padding-bottom: 5px;
}
.blog-icon-1 {
  position: relative;
  top: -4px;
}
.blog-btn-icon:hover .blog-icon-1 {
  top: 0;
  right: -140px;
  transition: 1s;
}

/*** Blog End ***/
/*** Footer ***/
.footer {
  background: url("/public/assets/footer1.png") center center no-repeat;
  background-size: contain;
  background-color: #14183e !important;
}
.footer a{
  text-decoration: none !important;
}
.footer-animation{
  visibility: visible;
  animation-delay: 0.7s;
  animation-name: fadeIn;
}
.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255,255,255,.5);
  border: 1px solid rgba(256, 256, 256, .1);
  border-radius: 40px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: rgba(255,255,255,.5);
  font-weight: normal;
  transition: .3s;
}

.footer .btn.btn-link:hover {
  color: #FFFFFF;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: rgba(255,255,255,.5);
}

.footer .copyright a:hover {
  color: #FFFFFF;
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
/*** Team Start ***/

.team-item {
  background: rgba(239, 239, 241, 0.8);
}

.team-content::before {
  height: 200px;
  display: block;
  content: "";
  position: relative;
  top: -102px;
  /* background: #34A753; */
  clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
  padding: 60px;
  opacity: 1;
}

.team-img-icon {
  position: relative;
  margin-top: -200px;
  padding: 30px;
  padding-bottom: 0;
  z-index: 100;
  max-width: 240px;
}

.team-img {
  border: 15px solid var(--bs-white);
}

.team-img img {
  border: 10px solid #34A753;
  transition: .5s;
}

.team-item:hover h4 {
  color: #1842b6;
  transition: .5s;
}

.team-item:hover .team-img img {
  transform: scale(1.05);
  border: 10px solid #34A753;
}

.team-carousel .owl-stage {
  position: relative;
  width: 100%;
  height: 100%;
}

.team-carousel .owl-nav {
  position: absolute;
  top: -100px;
  right: 50px;
  display: flex;
}

.btn-team-next,
.btn-team-prev {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  margin-left: 15px;
  background: #34A753;
  color: var(--bs-white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
}

.btn-team-prev:hover,
.btn-team-next:hover {
  background: #1842b6;
  color: var(--bs-white);
}
.team-carousel .carousel-control-prev,.team-carousel .carousel-control-next {
  display: none;
}
@media (max-width: 992px) {
  .team-carousel {
      margin-top: 3rem;
  }

  .team-carousel .owl-nav {
      top: -85px;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      margin-left: -15px;
  }
}

/*** Team End ***/
.contact-detail::before {
  position: absolute;
  content: "";
  height: 50%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(rgb(210, 243, 235, 1), rgba(230, 250, 245, .3)), url(/public/assets/backgroundcontactus.jpg) center center no-repeat;
  background-size: cover;
  border-radius: 10px;
  z-index: -1;
}

.contact-map {
  background: #3B5999;
}

.contact-form {
  background: #3B5999;
}
.contact-detail h4{
  text-decoration: none;
}
.contact-detail a{
  text-decoration: none;
  color: #34A753;
}
.feature {
  background: url(/public/assets/bg-hero.png) center center no-repeat;
  background-size: cover;
}
.btn-sm-square{
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}
.services-section{
  background: url(/public/assets/bg-hero.png) center center repeat;
}
.aboutImgs{
  gap: 10px 200px;
  align-items: center;
  justify-content: center;
}
.clients_wrap{
  /* margin-top: -16px; */
  /* background: rgb(24,66,182);
  background: linear-gradient(90deg, rgba(24,66,182,0.8) 0%, rgba(52,167,83,0.8) 100%); */
}
@media (max-width: 992px) {
  .aboutImgs{
    gap: 10px 50px;
  }
}

/*** Services Start ***/

.services .services-item {
  box-shadow: 0 0 60px rgba(0, 0, 0, .2);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px 0;
  position: relative;
}


.services-content::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  border-radius: 10px 10px 0 0;
  background: #1842b6;
  transition: .5s;
}

.services-content::after {
  top: 0;
  bottom: auto;
  border-radius: 10px 10px 10px 10px;
}

.services-item:hover .services-content::after {
  height: 100%;
  opacity: 1;
  transition: .5s;
}

.services-item:hover .services-content-icon {
  position: relative;
  z-index: 2;
}

.services-item .services-content-icon i,
.services-item .services-content-icon p {
  transition: .5s;
}

.services-item:hover .services-content-icon i {
  color: #34A753 !important;
}

.services-item:hover .services-content-icon p ,
.services-item:hover .services-content-icon h4 {
  color: var(--bs-white);
}
.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-img {
  position: relative;
  padding: 15px;
}

.project-img::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  left: 0;
  background: #34A753;
  border-radius: 10px;
  opacity: 1;
  z-index: -1;
  transition: .5s;
}

.project-img::after {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #1842b6;
  border-radius: 10px;
  opacity: 1;
  z-index: -1;
  transition: .5s;
}

.project-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.project-content a {
  display: inline-block;
  padding: 20px 25px;
  background: #1842b6;
  border-radius: 10px;
}

.project-item:hover .project-content {
  opacity: 1;
  transition: .5s;
}

.project-item:hover .project-img::before,
.project-item:hover .project-img::after {
  opacity: 0;
}
.project-content h4{
  text-decoration: none !important;
}
.project-content a{
  text-decoration: none !important;
}
.project-img img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}


/* Animation for floating effect */
@keyframes floating {
  0% { transform: translateY(0); }
  50% { transform: translateY(10px); }
  100% { transform: translateY(0); }
}

.floating {
  animation: floating 3s ease-in-out infinite;
}
/*** Services End ***/